<template>
    <div class="login">
        <div class="login-box">
            <div class="logo-box">
                <img src="../../assets/logo-new.png" alt="" />
            </div>
            <h1 class="login-title">{{ $t("login.title") }}</h1>

            <div class="form-box">
                <p class="form-label">{{ $t("账号") }}</p>
                <div class="form-input__box" style="margin-bottom: 18px">
                    <input type="text" :placeholder="$t('login.tip1')" v-model="username" />
                </div>
                <p class="form-label">{{ $t("密码") }}</p>
                <div class="form-input__box">
                    <input :type="inputType" :placeholder="$t('login.tip2')" v-model="password" />
                    <!-- <van-icon name="eye-o" size="24" v-if="inputType === 'text'" @click="inputType = 'password'"/>
				<van-icon name="closed-eye" size="24" v-else @click="inputType = 'text'"/> -->
                    <img src="../../assets/icon-eye-open.png" class="icon-eye" v-if="inputType === 'text'" @click="inputType = 'password'" alt="" />
                    <img src="../../assets/icon-eye-close.png" class="icon-eye" v-else @click="inputType = 'text'" alt="" />
                </div>
                <div class="forget-pwd">
                    <a href="">{{ $t("login.forget_password") }}？</a>
                </div>
            </div>

            <div class="place" style="height: 39px"></div>
            <button :disabled="loading" class="login-btn" @click="onSubmit">
                {{ $t("login.submit") }}
            </button>
            <router-link to="/register" class="login-register__text">{{ $t("login.text1") }}，{{ $t("login.to_register") }}</router-link>
        </div>
    </div>
</template>

<script>
import { login } from "@/api";
import { mapMutations } from "vuex";

export default {
    data() {
        return {
            inputType: "password",
            username: "",
            password: "",
            loading: false,
        };
    },
    methods: {
        ...mapMutations(["setToken"]),
        onSubmit() {
            if (!this.username) {
                this.$toast(this.$t("login.tip1"));
                return;
            }
            if (!this.password) {
                this.$toast(this.$t("login.tip2"));
                return;
            }
            this.loading = true;
            login({
                username: this.username,
                password: this.password,
            }).then(({ code, data, message }) => {
                this.loading = false;
                this.$toast(message);
                if (code === 200) {
                    this.setToken(data.token);
                    this.$router.push("/");
                }
            });
        },
    },
};
</script>

<style lang="less">
@import "../login.less";
</style>
